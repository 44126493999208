<template>
  <div class="login">
    <div class="blue-circle top"></div>
    <div class="blue-circle bottom"></div>
    <div class="logo">
      <img class="img" src="@/assets/images/logo@2x.png" alt="" />
      <p class="t1">家访处理中心</p>
      <p class="t2">Outsourcing home visit mobile platform</p>
    </div>
    <div class="login-cover">
      <img src="@/assets/images/login-cover@2x.png" alt="" />
    </div>

    <div class="login-form">
      <van-field
        type="tel"
        v-model="mobile"
        maxlength="11"
        clearable
        placeholder="请输入手机号"
        @change="checkPhone"
      >
        <template #left-icon>
          <i class="iconfont icon-mobile"></i>
        </template>
      </van-field>
      <van-field
        clearable
        v-model="password"
        type="digit"
        placeholder="请输入短信验证码"
        @change="checkWord"
      >
        <template #left-icon>
          <i class="iconfont icon-sms-solid"></i>
        </template>
        <template #button>
          <van-button
            color="#3C86FF"
            type="info"
            class="code"
            size="mini"
            v-if="isShowCodeText"
            :disabled="!subjectData.length"
            @click="getMobileCode()"
            >获取验证码</van-button
          >
          <van-button v-else size="mini" type="info" class="code">{{
            codeContent
          }}</van-button>
        </template>
      </van-field>
      <!-- <div class="radio-box" v-if="subjectData && subjectData.length >= 2">
        <van-radio-group class="radio" v-model="subject" direction="horizontal">
          <van-radio name="ENTERPRISE">企业</van-radio>
          <van-radio name="PERSONAL">个人</van-radio>
        </van-radio-group>
      </div> -->

      <div class="sign">
        <van-button
          color="#3C86FF"
          block
          type="info"
          :loading="loginLoading"
          class="sign-btn"
          @click="login"
          >登录</van-button
        >
      </div>
    </div>

    <div class="copyright">CopyRight © Songcw.com 2017 - 2022</div>
  </div>
</template>

<script>
import Api from "@/api/user";
import { Toast } from "vant";
import { storeUserInfo } from "@/libs/utils";
import watermark from '@/libs/watermark';
import { clearUserInfo } from "@/libs/utils";
import config from '@/config'

export default {
  data() {
    return {
      // 验证码
      totalTime: 60,
      codeContent: "获取验证码",
      isShowCodeText: true,
      clock: "",
      mobile: "",
      password: "",
      errorMessage: {
        mobile: "",
        password: "",
      },
      loginLoading: false,
      subject: "ENTERPRISE", // 默认选择企业
      subjectData: [],
    };
  },
  created() {},
  computed: {},
  watch: {
    mobile: {
      async handler(val) {
        if (val && this.checkPhone()) {
          try {
            const res = await Api.getFindByMobile(val);
            this.subjectData = res.data || [];
          } catch (error) {
            Toast.fail(error);
          }
        } else {
          this.subjectData = [];
        }
      },
      deep: true,
    },
  },
  methods: {
    // 验证码
    async getMobileCode() {
      if (!this.checkPhone()) {
        Toast.fail(this.errorMessage.mobile);
        return;
      }

      const loading = Toast.loading();
      try {
        this.isShowCodeText = false;

        await Api.sendValidateCode({
          mobile: this.mobile,
          smsCodeType: "LOGIN",
        });

        this.clock = window.setInterval(() => {
          this.totalTime--;
          if (this.totalTime < 0) {
            // 当倒计时小于0时清除定时器
            window.clearInterval(this.clock);
            this.totalTime = 60;
            this.isShowCodeText = true;
          }
          this.codeContent = `${this.totalTime}s后重新发送`;
        }, 1000);
        loading.close();
      } catch (err) {
        this.isShowCodeText = true;
        Toast(`${err}` || "发送失败");
      }
    },

    // 登录
    async login() {
      if (!this.checkPhone()) {
        return;
      }
      if (!this.checkWord()) {
        return;
      }

      let uName;
      // if (this.subjectData && this.subjectData.length === 1) {
      //   uName = `${this.mobile},${this.subjectData[0].subject}`;
      // } else if (this.subjectData && this.subjectData.length === 2) {
      //   uName = `${this.mobile},${this.subject}`;
      if (this.subjectData && this.subjectData.some(e => e.subject == 'ENTERPRISE')) {
        uName = `${this.mobile},ENTERPRISE`;
      } else if (this.subjectData && this.subjectData.length) {
        uName = `${this.mobile},PERSONAL`;
      } else {
        uName = this.mobile;
      }

      try {
        this.loginLoading = true;
        const res = await Api.loginSms({
          grant_type: "password",
          username: uName,
          scope: "userProfile",
          smsCode: this.password,
          deviceId: " ",
        });
        console.log('res :>> ', res);
        this.loginSuccess(res);
      } catch (error) {
        Toast.fail(error);
      } finally {
        this.loginLoading = false;
      }
    },

    // 登录成功
    loginSuccess(res) {
      clearUserInfo();
      storeUserInfo(res.data);
      this.$router.push("/");
      watermark(document.getElementById("app"))
    },

    // 验证手机号码格式
    checkPhone() {
      const value = this.mobile;
      const phoneReg = /^1\d{10}$/;
      if (!value) {
        this.errorMessage.mobile = "手机号码不能为空";
        return false;
      }
      if (!phoneReg.test(value)) {
        this.errorMessage.mobile = "手机号码格式错误";
        return false;
      }
      this.errorMessage.mobile = "";
      return true;
    },

    // 验证码
    checkWord() {
      const value = this.password;
      if (!value) {
        this.errorMessage.password = "验证码不能为空";
        return false;
      }
      this.errorMessage.password = "";
      return true;
    },
  },
  mounted() {
    watermark(document.getElementById("app"), {text: config.title})
    // this.loginSuccess({
    //   success: true,
    //   message: "成功",
    //   path: "/login/sms/token",
    //   data: {
    //     userId: "602841144514052096",
    //     username: "17723116549",
    //     realName: "楚昌明",
    //     tokenInfo: {
    //       access_token: "8db6cd0c-671f-421f-9df6-3b228e407868",
    //       refresh_token: "c2500c25-cdf9-4281-93e8-a1d0f459f7e1",
    //       scope: "userProfile",
    //       token_type: "bearer",
    //       expires_in: 1199999,
    //     },
    //   },
    //   code: "0000",
    //   timestamp: "1623895375481",
    // });
  },
};
</script>
<style lang="less"  scoped>
.blue-circle {
  width: 118px;
  height: 10px;
  background: #3c86ff;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  &.top {
    border-radius: 0px 0px 100px 100px;
    top: 0px;
  }
  &.bottom {
    border-radius: 100px 100px 0px 0px;
    bottom: 0px;
  }
}
.logo {
  padding: 44px 38px;
  .img {
    width: 49px;
    display: block;
  }
  .t1 {
    margin-top: 20px;
    font-weight: Medium;
    font-size: 20px;
    color: #212528;
    letter-spacing: 0;
  }
  .t2 {
    margin-top: 3px;
    font-weight: Medium;
    font-size: 9px;
    color: #9a9ea1;
    letter-spacing: 0;
  }
}

.login-cover {
  width: 250px;
  margin: 0px auto;
  img {
    max-width: 100%;
  }
}

.login-form {
  position: relative;
  width: 312px;
  min-height: 209px;
  background: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(72, 88, 115, 0.01);
  border-radius: 6px;
  margin: -70px auto 0px auto;
  padding: 28px 23px;

  .van-field {
    align-items: center;
    border: 1px solid #ececec;
    border-radius: 4px;
    font-size: 14px;
    padding: 8px 5px;

    &::after {
      display: none;
    }

    &:nth-child(n + 2) {
      margin-top: 16px;
    }

    /deep/ .van-field__body {
      height: 30px;
    }
    /deep/ .van-field__button {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ::placeholder {
      font-size: 12px;
    }
  }

  .iconfont {
    color: #9a9ea1;
    text-align: center;
    display: inline-block;
    width: 34px;
    height: 24px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &.icon-mobile {
      // margin-top: 4px;
      font-size: 28px;
    }
    &.icon-sms-solid {
      font-size: 22px;
    }
  }
}

.copyright {
  margin-top: 40px;
  font-weight: Medium;
  font-size: 10px;
  color: #9a9ea1;
  letter-spacing: 0;
  text-align: center;
}

.flex {
  display: flex;
  flex-direction: row;
}
.code {
  white-space: nowrap;
}

/deep/.van-cell-group {
  &:after {
    border: 1px solid #ebedf0;
  }
}
.sign {
  margin-top: 16px;
  border-radius: 3px;

  .sign-btn {
    font-weight: Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
  }
}
.forget {
  font-size: 14px;
  color: #888;
}
.radio-box {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
